import React from 'react';
import styles from './Button.module.scss';
import Link from 'next/link';

import cn from 'classnames';

interface ButtonProps {
  placeholder: string;
  addStyles?: any;
  onClick?: () => void;
  href?: string;
  disabled?: boolean;
  type?: any;
  target?: string;
  rel?: string;
}

const Button: React.FC<ButtonProps> = ({
  placeholder,
  addStyles = [],
  onClick,
  href,
  disabled,
  type,
  target,
  rel,
}) => {
  const style = cn(styles.button, ...addStyles);

  const Tag = href ? 'a' : 'button';

  return (
    <>
      {Tag === 'a' ? (
        <Link href={href}>
          <Tag
            className={style}
            onClick={onClick}
            href={href}
            type={type}
            target={target}
            rel={rel}
          >
            {placeholder}
          </Tag>
        </Link>
      ) : (
        <Tag
          className={style}
          onClick={onClick}
          type={type}
          disabled={disabled}
        >
          {placeholder}
        </Tag>
      )}
    </>
  );
};

export default Button;
